<template>
    <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleFormRef" label-width="120px" style='min-width="800px"'>
            <el-form-item label="通知名称:" prop="name">
                <el-input v-model='ruleForm.name'></el-input>
            </el-form-item>
            <el-form-item label="通知方式:" prop="noticeWay">
                <el-radio-group v-model="ruleForm.noticeWay">
                    <el-radio :label="0">短信</el-radio>
                    <el-radio :label="1">语音</el-radio>
                    <el-radio :label="2">站内信</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label='内容:' prop='content'>
                <el-input
                    type="textarea"
                    :rows="9"
                    placeholder="请输入内容"
                    v-model="ruleForm.content"
                    clearable
                    maxlength="300">
                </el-input>
                <template>格式：【签名】+内容+回T退订。字数：<span style='color:red;'>{{this.ruleForm.content.length}}</span></template>
            </el-form-item>
            <el-form-item label='计划发送时间:' prop='startTime'>
                <el-date-picker
                    v-model="ruleForm.startTime"
                    type="datetime"
                    :picker-options="pickerOptions"
                    placeholder="选择日期时间"
                    @change='timeChange'>
                </el-date-picker>
            </el-form-item>
            <!-- <el-form-item label='发送频率' prop='count'>
                <el-select v-model="ruleForm.count" placeholder="无">
                    <el-option
                        v-for="item in options2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                </el-select>
                <span style='margin-left:15px;'>共1次</span>
            </el-form-item> -->
            <el-form-item label='接收对象:' prop='phoneNumberLibraryID'>
                <el-row>
                    <el-col :span='24'>
                        <el-select v-model="ruleForm.phoneNumberLibraryID" placeholder="请选择">
                            <el-option
                                v-for="item in options"
                                :key="item.ID"
                                :label="item.Name"
                                :value="item.ID">
                            </el-option>
                            <el-col :span='24'>
                                <el-pagination
                                small                             
                                @current-change="handleCurrentChange"
                                :current-page="this.pages.pageIndex"
                                layout="total, prev, pager, next"
                                :total="this.pages.dataCount">
                                </el-pagination>
                            </el-col>
                        </el-select>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button type='primary' @click='send' :loading="addLoading">发送</el-button>
                <el-button type='primary' @click='clear' style='margin-left:100px;'>清空</el-button>
            </el-form-item>
            <el-form-item v-if='ruleForm.noticeWay == 0'>
                <p style='color:red;'>每次发送号码数量不能大于 50000 个号码。</p>
                <p style='color:red;'>短信内容不能超过 300 个字。70 个字以内按 1 条计费，超过 70 个字按每条 67 个字计费。</p>
            </el-form-item>          
        </el-form>
    </div>
</template>

<script>
import {addSendNotification,getPhoneNumberLibraryListPage} from '@/api/api'
import Qs from "qs";
export default {
    data(){
        let checkTime = (rule,value,callback) => {
            var selTime = Math.round(new Date(value).getTime() / 1000)
            if(selTime < this.startTime || selTime > this.endTime){
                return callback(new Error('免打扰时间从23:00到07:00,此段时间内不能发送系统通知！'))
            }
            if(selTime < Math.round(new Date().getTime() / 1000)){
                return callback(new Error('请选择当前之后时间'))
            }else{
                return callback()
            } 
        }
        return {
            pickerOptions:{
                disabledDate:(time)=> {
                    return time.getTime() < Date.now() - 8.64e7;
                }
            },
            addLoading:false,
            PhoneNumberLibrary:0, // 号码库数量
            options:[],
            // options1:[
            //     {
            //         label:'考试',
            //         value:0
            //     },
            //     {
            //         label:'学习',
            //         value:1
            //     }
            // ],
            // options2:[
            //     {
            //         label:'无',
            //         value:''
            //     },
            //     {
            //         label:'每日此时',
            //         value:0
            //     },
            //     {
            //         label:'每月此时',
            //         value:1
            //     },
            //     {
            //         label:'每季度此时',
            //         value:2
            //     },
            //     {
            //         label:'每年此时',
            //         value:3
            //     }
            // ],
            ruleForm:{
                name:'',
                noticeWay:0,
                content:'',
                startTime:this.dateFormat('YYYY-mm-dd HH:MM:SS',new Date()),
                // count:'',
                phoneNumberLibraryID:'',
                // type:'',
            },
            rules:{
                name:[{required:true,message:'请选择通知名称',trigger:'blur'}],
                noticeWay:[{required:true,message:'请选择通知方式',trigger:'change'}],
                content:[{required:true,message:'请输入内容',trigger:'blur'}],
                startTime:[
                    {validator:checkTime,required:true,trigger:'change'}
                ],
                // count:[{required:true,message:'请选择发送频率',trigger:'change'}],
                phoneNumberLibraryID:[{required:true,message:'请选择接受对象',trigger:'change'}],
                // type:[{required:true,message:'请选择通知类型',trigger:'change'}]
            },
            startTime:'',  //7点的时间戳
            endTime:'',  //23点的时间戳
            pages:{
                pageSize:10,
                pageIndex:1,
                dataCount:0,
            }
        }
    },
    methods:{
        // 默认选中当前时间
        dateFormat(format,date){
            let ret = ''
            date = date
            const option = {
                'Y+':date.getFullYear().toString(),  //
                'm+':(date.getMonth() + 1).toString(),
                'd+':date.getDate().toString(),
                'H+':date.getHours().toString(),
                'M+':date.getMinutes().toString(),
                'S+':date.getSeconds().toString()
            }
            for(let k in option){
                ret = new RegExp('('+k+')').exec(format)
                if(ret){
                    format = format.replace(
                        ret[1],
                        ret[1].length == 1? option[k] :option[k].padStart(ret[1].length,'0')
                    )
                }
            }
            return format
        },
        // 用户改变时间值
        timeChange(value){
            // 选择的时间
            this.ruleForm.startTime = this.dateFormat('YYYY-mm-dd HH:MM:SS',new Date(value))
        },
        // 清空表单
        clear(){
            this.$refs['ruleFormRef'].resetFields()
        },
        send(){
            var params = {...this.ruleForm}          
            this.$refs['ruleFormRef'].validate(valid => {
                    if(valid){
                        this.addLoading = true
                        addSendNotification(params).then(res => {
                            if(res.data.Success){
                                this.$message({
                                    message:res.data.Message,
                                    type:'success'
                                })
                                this.clear()
                                this.addLoading = false;
                            }else{
                                this.$message({
                                    message:res.data.Response.message,
                                    type:'error'
                                })
                                var _this = this
                                setTimeout(() => {
                                    _this.addLoading = false;
                                },3000)
                            }
                        })
                    }else{
                        return
                        }
                
            })                   
        },
        // 得到号码库
        getNumbers(){
            var params = {
                name:'',
                pageIndex:this.pages.pageIndex,
                pageSize:this.pages.pageSize
            }
            getPhoneNumberLibraryListPage(params).then(res => {
                if(res.data.Success){           
                    this.pages.dataCount = res.data.Response.DataCount     
                    var data = res.data.Response.Data
                    this.options = data
                }else{
                    this.$message.error(res.data.Message)
                }
            })
        },
        // 接受对象处理分页
        handleCurrentChange(value){
            this.pages.pageIndex = value
            this.getNumbers()
        },
    },
    created(){
        this.time = this.dateFormat('YYYY-mm-dd HH:MM:SS',new Date())
        this.getNumbers()
    },
    mounted(){
        var start  = new Date(new Date(new Date().toLocaleDateString()).getTime()+7*60*60*1000)
        var end  = new Date(new Date(new Date().toLocaleDateString()).getTime()+23*60*60*1000)
        var STime = start.getFullYear() + "-" + ((start.getMonth() + 1) < 10 ? "0" + (start.getMonth() + 1):(start.getMonth() + 1))+ "-" + (start.getDate() < 10 ? "0" + start.getDate():start.getDate()) + " " + (start.getHours()<10?"0"+start.getHours():start.getHours()) + ":" + (start.getMinutes()<10?"0"+start.getMinutes():start.getMinutes()) + ":" + (start.getSeconds()<10?"0"+start.getSeconds():start.getSeconds())
        var ETime = end.getFullYear() + '-' + ((end.getMonth() + 1) < 10 ? "0" + (end.getMonth() + 1):(end.getMonth() + 1))+ "-" + (end.getDate() < 10 ? "0" + end.getDate():end.getDate()) + " " + (end.getHours()<10?"0"+end.getHours():end.getHours()) + ":" + (end.getMinutes()<10?"0"+end.getMinutes():end.getMinutes()) + ":" + (end.getSeconds()<10?"0"+end.getSeconds():end.getSeconds())
        this.startTime = Math.round(new Date(STime) / 1000)
        this.endTime = Math.round(new Date(ETime) / 1000)
    }
}
</script>

<style lang="stylus" scoped>
    .el-form{
        width:700px!important;
    }
</style>